<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="pb-0"
          style="background-color: #7253cf; color: white"
        >
          <div
            class="ml-7 mb-3 dialogTitle-text text-center"
            style="width: 85%"
          >
            <div class="text-center">Select Audience</div>
            <div class="text-center" style="font-size: 35px; color: #ffd54f">
              {{ audienceCount }}
            </div>
            <div
              class="text-center"
              style="font-size: 14px; font-family: Lato"
            ></div>
          </div>
          <v-icon
            text
            size="32"
            color="white"
            @click="toggleAudienceModal({ show: false })"
            style="cursor: pointer; position: absolute; right: 10px; top: 10px"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-10 px-4">
          <v-row no-gutters>
            <v-col cols="12" class="px-3 pb-0 mt-2">
              <div style="font-size: 18px">Send to</div>
            </v-col>
            <v-col cols="12" class="px-3 pt-0">
              <!-- <v-checkbox
                  color="#7253CF"
                  class="formFields"
                  v-model="item.key"
                  :label="item.text"
                  @change="storeAudience(item.key, item.value)"
                ></v-checkbox> -->
              <!-- {{ selectedAudience }} -->
              <v-radio-group v-model="selectedAudience" row>
                <v-radio
                  v-for="(item, i) in audienceData"
                  :key="i"
                  :label="item.text"
                  :value="item.value"
                  class="py-2"
                  color="#7253CF"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="d-flex justify-end pb-8 px-6"
          v-if="this.audienceCount > 0"
        >
          <v-btn
            dark
            height="45"
            color="#2c1963"
            class="text-capitalize px-7"
            style="border-radius: 10px"
            :loading="formLoading"
            @click="submitForm"
          >
            <span>Proceed to compose template</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "@/constants/urls";
import {
  API_ADMIN_GET_DISTRICT_LIST,
  API_ADMIN_GET_SCHOOL_LIST,
  API_ADMIN_CAMPAIGN_MANAGEMENT_LIST,
  API_BULK_EMAIL_GAME_AUDIENCE_COUNT,
} from "@/constants/APIUrls";
export default {
  name: "BulkEmailAudienceModal",
  data() {
    return {
      loading: false,
      formLoading: false,
      district: null,
      districtList: [],
      school: null,
      schoolList: [],
      campaign: null,
      campaignList: [],
      audienceList: [],
      audienceData: [
        { key: false, value: "host", text: "Host" },
        { key: false, value: "home_team", text: "Home Team" },
        { key: false, value: "committee", text: "Committee" },
      ],
      audienceEditData: [],
      selectedAudience: "",
      audienceCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "emailSettings/getShow",
      getEmailDetail: "emailSettings/getComposeEmailDetail",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAudienceModal({ show: value });
      },
    },
    layout() {
      return this.$route.meta.layout;
    },
    type() {
      return this.$store.state.emailSettings.addEditViewModal.type;
    },
  },
  watch: {
    show(value) {
      if (value) {
        setTimeout(() => {
          this.openCallModal();
        }, 100);
      } else {
        this.closeCallModal();
      }
    },
    district: function () {
      this.getCounts();
      if (this.district) {
        this.getSchool();
      }
    },
    school: function () {
      this.getCounts();
      if (this.school) {
        this.getCampaign();
      }
    },
    campaign() {
      this.getCounts();
    },
    selectedAudience() {
      this.getCounts();
    },
  },
  methods: {
    ...mapActions({
      toggleAudienceModal: "emailSettings/toggleModal",
      toggleEmailDetail: "emailSettings/toggleEmailDetail",
    }),
    openCallModal() {
      console.log(this.selectedCampaign, "campaign details");
      this.getCounts();
      if (this.type === "edit") {
        this.getDetail();
        this.loading = true;
      }
    },
    closeCallModal() {
      this.district = null;
      this.districtList = [];
      this.school = null;
      this.schoolList = [];
      this.campaign = null;
      this.campaignList = [];
      this.audienceList = [];
      this.audienceData = [
        { key: false, value: "host", text: "Host" },
        { key: false, value: "home_team", text: "Home Team" },
        { key: false, value: "committee", text: "Committee" },
      ];
      this.audienceCount = 0;
      this.selectedAudience = "";
    },
    getDistrict() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.districtList = res.data.district_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSchool();
      };
      let formData = {};
      Axios.request_GET(
        API_ADMIN_GET_DISTRICT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSchool() {
      const successHandler = (res) => {
        console.log(res.data);
        this.schoolList = res.data.school_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getCampaign();
      };
      let formData = {};
      if (this.district) {
        formData["district_id"] = this.district;
      }
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getCampaign() {
      const successHandler = (res) => {
        console.log(res.data);
        this.campaignList = res.data.campaign_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };

      let formData = {};
      if (this.district) {
        formData["district_id"] = this.district;
      }
      if (this.school) {
        formData["school_id"] = this.school;
      }
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getDetail() {
      console.log(this.getEmailDetail);
      this.selectedAudience = this.getEmailDetail.audience.value;
      this.game = this.getEmailDetail.game.id;
    },
    submitForm() {
      let gameDetail = {};
      let audienceDetail = {};

      if (this.selectedAudience) {
        let index = this.audienceData.findIndex(
          (item) => item.value === this.selectedAudience
        );
        audienceDetail = { ...this.audienceData[index] };
      }
      let emailDetail = {
        game: gameDetail,
        audience: audienceDetail,
      };
      this.toggleEmailDetail(emailDetail);
      this.toggleAudienceModal({ show: false });
      if (this.layout === "AdminDashboardLayout") {
        if (this.type === "add") {
          this.$router.push({
            name: ROUTER_URL.gamePanel.children.bulkGameEmailForm.name,
            query: { type: "add" },
          });
        } else {
          this.$emit("reload");
        }
      } else {
        this.$route.query["type"] = "add";
        this.$emit("reload");
        this.$root.$refs.bulkGameEmailForm.changeTab(false);
      }
    },

    getCounts() {
      const successHandler = (res) => {
        this.audienceCount = res.data.total_audience;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {};
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      if (this.selectedAudience) {
        formData["send_to"] = this.selectedAudience;
      }
      Axios.request_GET(
        API_BULK_EMAIL_GAME_AUDIENCE_COUNT,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
}
.v-btn >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  /* or 29px */
  color: #ffffff;
  letter-spacing: 0px;
  font-size: 17px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
</style>
